import React from "react";
import type { AppProps } from "next/app";
import "../globalStyles.css";
import FaviconHead from "client/components/FaviconHead/FaviconHead";
import Head from "next/head";

const MyApp: React.FC<AppProps> = ({
  Component,
  pageProps,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  err,
}) => {
  return (
    <div suppressHydrationWarning>
      <link
        rel="shortcut icon"
        id="favicon"
        href="/favicon/orange/favicon.ico"
      />
      <FaviconHead />
      <Head>
        {/* <meta property="og:url" content={currentURL} key="ogurl" /> */}
        <meta
          property="og:image"
          content={"https://boredspace.games/images/BORED_SPACE_GAMES.jpg"}
          key="ogimage"
        />
        <meta
          property="og:site_name"
          content={"BORED SPACE GAMES"}
          key="ogsitename"
        />
        <meta
          property="og:description"
          content={"BORED! SPACE! GAMES!"}
          key="ogdesc"
        />
        <title key={"title"}>BORED SPACE GAMES</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content={"BORED SPACE GAMES"} key="ogtitle" />
        <script
          defer
          data-domain="boredspace.games"
          data-api="/plausible/api/event"
          src="/plausible/js/script.js"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }`,
          }}
        />
      </Head>
      {typeof window === "undefined" ? null : (
        <Component {...pageProps} err={err} />
      )}
    </div>
  );
};

export default MyApp;
