import { Color, EntityId } from "@bsg/shared/types";
import create from "zustand";
import produce from "immer";
import { Vector3 } from "three";

export type ControlsState = {
  hoveredEntityId: EntityId | null;
  hoveredPointVerticalOffset: number; // this reduces render collision on equal-z entities.
  hoveredPoint: Vector3;
  clientColor: Color;
  paused: boolean;
  showHand: boolean;
  pointerLocked: boolean;

  set: (edit: (state: ControlsState) => void) => void;
  randomizeVerticalOffset: () => void;
  togglePaused: (override?: boolean) => void;
  reset: (keepClientColor: boolean) => void;
};

const INITIAL_STATE = {
  hoveredEntityId: null,
  hoveredPointVerticalOffset: 0,
  hoveredPoint: new Vector3(),
  clientColor: null,
  paused: false,
  showHand: false,

  // If pointer lock is allowed, it may not always be active because of browser.
  // It should also not be active during the pause menu
  pointerLocked: false,
};

const store = (set): ControlsState => ({
  ...INITIAL_STATE,
  set: (fn) => set(produce(fn)),
  togglePaused: (override) => {
    set(
      produce((state) => {
        const newPaused = override ?? !state.paused;
        state.pointerLocked = !newPaused;
        state.paused = newPaused;
      })
    );
  },
  randomizeVerticalOffset: () => {
    set(
      produce((state) => {
        state.hoveredPointVerticalOffset = Math.random() * 0.01;
      })
    );
  },
  // We want to skip reseting client color often, because this triggers all kinds of stuff for rendering the player vs. client
  reset: (keepClientColor) => {
    console.log("RESETTING CONTROL STORE");
    set(
      produce((state: ControlsState) => {
        Object.entries(INITIAL_STATE).forEach(([key, value]) => {
          state[key] = value;
          if (keepClientColor && key === "clientColor") {
            return;
          }
        });
      })
    );
  },
});

const useControlsStore = create<ControlsState>(store);

export { useControlsStore };
