import React, { FunctionComponent, useEffect } from "react";
import { useControlsStore } from "client/game/controls";

const _FaviconHead: FunctionComponent = () => {
  const color = useControlsStore((state) => state.clientColor);
  useEffect(() => {
    const route = `/favicon/${
      color ? color.toLocaleLowerCase() : "orange"
    }/favicon.ico`;
    const faviconElement = document.getElementById(
      "favicon"
    ) as HTMLLinkElement;
    console.log("switching href to: ", route);
    if (faviconElement) {
      faviconElement.href = route;
    }
  }, [color]);
  return null;
};

_FaviconHead.displayName = "FaviconHead";
const FaviconHead = React.memo(_FaviconHead);
export default FaviconHead;
